import React, { useState, useEffect, useRef } from 'react';

import useLanguage from '../_hooks/useLanguage';
import useMessage from '../_hooks/useMessage';
import useApp from '../_hooks/useApp';
import useAuction from '../_hooks/useAuction';
import useUser from '../_hooks/useUser';
import Modal from '../modal/modal';

const SystemMessages = () => {
	const { t } = useLanguage();
	const { appReset } = useApp();
	const { authenticated } = useUser();
	const { getAuctionIdFromUrl } = useAuction();
	const { messageData: { socket, socketId: _socketId }, messageDispatch } = useMessage('system-messages');

	const [action, setAction] = useState(null);
	const [isDev] = useState(process.env.NODE_ENV === 'development');

	const socketId = useRef(null);

	useEffect(() => {
		if (_socketId) socketId.current = _socketId;
	}, [_socketId]);

	useEffect(() => {
		if (socket !== null) {
			socket.on('system-message-private', (d) => {
				if (d.name) {
					if (isDev) {
						console.log('SYSTEM MESSAGE [PRIVATE]', d);
					}

					/*
					switch (d.name) {
					case 'demo-private-message': console.log('demo private message'); break;
					default: break;
					}
					*/
				}
			});

			socket.on('system-message-public', (d) => {
				if (d.name) {
					/*
					if (isDev) {
						console.log('SYSTEM MESSAGE [PUBLIC]', d);
					}
					*/

					switch (d.name) {
					case 'force-reload': window.location.reload(); break; // reload page
					case 'reset': appReset(true); break; // clear local storage & reload
					case 'new-version': setAction('new-version'); break; // show popup, that new version is available

					case 'auction-bid':
					case 'auction-sync-status':
						if (authenticated) {
							const auctionId = getAuctionIdFromUrl(); // real estate id

							if (auctionId) {
								if (d.channel_id === `auction-bid-${auctionId}`) { // if change "key", change in aside-bidding.js too!
									messageDispatch({ type: 'SET_LAST_MESSAGE', payload: d });
								}
							}
						}
						break;

					default: break;
					}
				}
			});
		}
	}, [socket]);

	if (action === null) return null;

	return (
		<>
			{action === 'new-version' && <Modal component="system/updater" visible props={{ title: t('updaterTitle'), text: t('updaterText') }} zIndex={2000} />}
		</>
	);
};

export default SystemMessages;
